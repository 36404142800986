import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const UploadFailedDialog = ({open, okClicked}) => {
    const {t} = useTranslation('common');
    const {t: tUpload} = useTranslation('uploadForm');
    return (
        <Dialog open={open}>
            <DialogTitle id="alert-dialog-title">{tUpload('Uploading Failed Title')}</DialogTitle>
            <DialogContent dividers>
                {tUpload('Uploading Failed')}
            </DialogContent>
            <DialogActions>
                <Button onClick={okClicked} color="secondary">
                    {t('OK Button')}
                </Button>
            </DialogActions>
        </Dialog>);
};

UploadFailedDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    okClicked: PropTypes.func.isRequired
};

export default UploadFailedDialog;