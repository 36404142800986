import React from 'react';
import FormElement from "./FormElement";
import {CircularProgress} from "@material-ui/core";

const FormElementLoading = () => {
    return (
       <FormElement>
           <CircularProgress />
       </FormElement>
    );
};

export default FormElementLoading;