let env = window.Environment || {};

const config = {
    keyCloak: {
        host: env.REACT_APP_KEYCLOAK_HOST !== 'undefined' ? env.REACT_APP_KEYCLOAK_HOST : "http://localhost:9090/auth",
        client: env.REACT_APP_KEYCLOAK_CLIENT !== 'undefined' ? env.REACT_APP_KEYCLOAK_CLIENT : "fe-nginx",
        realm: env.REACT_APP_KEYCLOAK_REALM !== 'undefined' ? env.REACT_APP_KEYCLOAK_REALM : "DigitalArch"
    },
    apiGateway: {
        host: env.REACT_APP_API_GATEWAY_HOST !== 'undefined' ? env.REACT_APP_API_GATEWAY_HOST : "http://localhost:9090/api/v1"
    },
    elasticsearch: {
        username: env.REACT_APP_ELASTIC_USER !== 'undefined' ? env.REACT_APP_ELASTIC_USER : "digitalarch",
        password: env.REACT_APP_ELASTIC_PASS !== 'undefined' ? env.REACT_APP_ELASTIC_PASS : "digitalarch-client",
        url: env.REACT_APP_ELASTIC_URL !== 'undefined' ? env.REACT_APP_ELASTIC_URL : "http://localhost:9090/",
    },
    documents: {
        deletionDelay: env.REACT_APP_DOCDELETION_DELAY !== 'undefined' ? env.REACT_APP_DOCDELETION_DELAY : 3000
    }
}

export default config;
