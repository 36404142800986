import React, {useState} from 'react';
import SingleOption from "./SingleOption";
import {MultiDropdownList} from "@appbaseio/reactivesearch";
import PropTypes from "prop-types";

const MultiOptionSelect = ({
                               id,
                               field,
                               textProvider,
                               title
                           }) => {
    const [items, setItems] = useState([]);
    return (
        <MultiDropdownList
            title={title}
            renderLabel={() => (<div>{items.map((item, i) => (<span key={i}>{textProvider(item)}</span>))}</div>)}
            dataField={field}
            onValueChange={setItems}
            componentId={id}
            renderItem={label => (<SingleOption value={label} text={textProvider(label)}/>)}
        />
    );
}

MultiOptionSelect.propTypes = {
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    textProvider: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
}

export default MultiOptionSelect;