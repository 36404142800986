import Grid from "@material-ui/core/Grid";
import Panel from "../container/Panel";
import {useTranslation} from "react-i18next";
import RequestStatusDataGrid from "../components/status/RequestStatusDataGrid";
import RequestStatusDataProvider from "../components/status/RequestStatusDataProvider";
import {makeStyles} from "@material-ui/core/styles";
import {Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {Done, Error, Help, ReportProblem} from "@material-ui/icons";
import {useState} from "react";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        [theme.breakpoints.up('md')]: {
            // Styles for screens with a width of 960px and above
            display: 'flex',
            flexDirection: 'column',
        },
    },
    panelContainer: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            // Styles for screens with a width of 960px and above
            flexDirection: 'row',
        },
    },
}));

const Home = () => {
    const {t} = useTranslation("status");
    const classes = useStyles();
    const [errorInfo, setErrorInfo] = useState(null);

    const showErrorInfo = row => {
        setErrorInfo(row.requestStatusValue === "ERROR" ? row.errorMessage : null);
    }

    function changePage(setCurrentPage, pageChangeArgs) {
        setErrorInfo(null);
        return setCurrentPage(pageChangeArgs.page);
    }

    return (
        <Grid container className={classes.panelContainer}>
            <Grid item xs={12} md={10}>
                <Panel title={t("Page Title")} className={classes.root}>
                    <RequestStatusDataProvider
                        render={(data, setCurrentPage, pageSize, totalElements) => (
                            <RequestStatusDataGrid
                                onRowClick={row => showErrorInfo(row)}
                                pageSize={pageSize}
                                onCurrentPageChange={pageChangeArgs => changePage(setCurrentPage, pageChangeArgs)}
                                totalElements={totalElements}
                                data={data}/>)}
                    />
                </Panel>
                {errorInfo &&
                <Panel title={t("Error Info")}>
                    <Typography variant="body1" color="error" style={{textAlign: "left"}}>
                        {errorInfo}
                    </Typography>
                </Panel>}
            </Grid>
            <Grid item xs={12} md={2}>
                <Panel title={t("Info")}>
                    <div>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell><Done/></TableCell>
                                    <TableCell>{t("Done")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Error/></TableCell>
                                    <TableCell>{t("Error")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Help/></TableCell>
                                    <TableCell>{t("Non existent")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><ReportProblem/></TableCell>
                                    <TableCell>{t("Unknown")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Panel>
            </Grid>
        </Grid>
    );
};

export default Home;