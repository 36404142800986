import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import YesNoConfirmationDialog from "../YesNoConfirmationDialog";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import config from "../../../app/config";
import {deleteDocument} from "../../../app/singledoc/apiCalls";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import DelayInfo from "../DelayInfo";

const useStyles = makeStyles(() => ({
    paper: {
        width: '40%',
        maxHeight: 300,
        textAlign: "center"
    },
}));

const RemovalQuestion = ({title}) => (
    <Typography variant="h6" color="primary">
        {title}
    </Typography>
)

const DeleteDocumentDialog = ({requestId, seqNo, primaryTerm, open, onClose, nextLocation, onError}) => {
    const classes = useStyles();
    const {t} = useTranslation("document");
    const history = useHistory();
    const [confirm, setConfirm] = useState(true);

    function closeDialog() {
        onClose();
        setConfirm(true);
    }

    return (
        <YesNoConfirmationDialog
            classes={{
                paper: classes.paper
            }}
            okClicked={() => {
                deleteDocument(requestId, seqNo, primaryTerm)
                    .then(resp => {
                        if (!resp.ok) {
                            throw new Error(`Deletion failed with ${resp.status}`);
                        }
                        setTimeout(() => {
                            closeDialog();
                            history.push(nextLocation)
                        }, config.documents.deletionDelay);
                    })
                    .catch(e => {
                        closeDialog();
                        onError(e)
                    })
                setConfirm(false);
            }}
            noClicked={() => {
                onClose();
            }}
            open={open}
            showActions={confirm}
            title={t("Remove")}>
            {confirm && <RemovalQuestion title={t("Removal Confirmation")}/>}
            {!confirm && <DelayInfo title={t("Removal Delay")}/>}
        </YesNoConfirmationDialog>
    );
};

DeleteDocumentDialog.propTypes = {
    requestId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    nextLocation: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    seqNo: PropTypes.string.isRequired,
    primaryTerm: PropTypes.string.isRequired
}

export default DeleteDocumentDialog;