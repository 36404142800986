import React from 'react';
import {DropzoneAreaBase} from "material-ui-dropzone";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {Description} from "@material-ui/icons";

const useStyles = makeStyles(() => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    header: {
        textAlign: "left"
    }
}));

const FilesDropZone = ({
                           previewText,
                           files,
                           headerText,
                           onSelectedFilesChanged,
                           onFileRemoved,
                           dropzoneTitle,
                           maxFilesCount,
                           maxFileSize
                       }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.header}
                avatar={<Description/>}
                title={headerText}
            />
            <CardContent>
                <DropzoneAreaBase
                    fileObjects={files}
                    onAdd={onSelectedFilesChanged}
                    onDelete={onFileRemoved}
                    showPreviews={true}
                    showAlerts={['error']}
                    showPreviewsInDropzone={false}
                    dropzoneText={dropzoneTitle}
                    useChipsForPreview
                    filesLimit={maxFilesCount}
                    maxFileSize={maxFileSize}
                    previewGridProps={{container: {spacing: 1, direction: 'row'}}}
                    previewChipProps={{classes: {root: classes.previewChip}}}
                    previewText={previewText}/>
            </CardContent>
        </Card>
    );
};

FilesDropZone.propTypes = {
    previewText: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    onSelectedFilesChanged: PropTypes.func.isRequired,
    onFileRemoved: PropTypes.func.isRequired,
    dropzoneTitle: PropTypes.string.isRequired,
    maxFilesCount: PropTypes.number,
    maxFileSize: PropTypes.number,
}

FilesDropZone.defaultProps = {
    maxFilesCount: 3,
    maxFileSize: 1000000
}

export default FilesDropZone;