import React from 'react';
import Panel from "../container/Panel";
import UploadForm from "../components/UploadForm";
import {useTranslation} from "react-i18next";

const Upload = () => {
    const {t} = useTranslation("uploadForm")

    return (
        <Panel title={t("Document upload")}>
            <UploadForm t={t} referenceDataApiPath="/uploadform/referencedata"/>
        </Panel>
    )
}

export default Upload;