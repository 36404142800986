import React from 'react';
import Panel from "../container/Panel";
import UploadForm from "../components/UploadForm";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const Edit = () => {
    const {t} = useTranslation("uploadForm")
    const history = useHistory();
    let document = history.location.state?.document

    return (
        <Panel title={t("Document edit")}>
            <UploadForm
                t={t}
                doc={document}
                referenceDataApiPath="/uploadform/referencedata"
            />
        </Panel>
    )
}

export default Edit;