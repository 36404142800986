const PAGE_IMAGE_FILES = "pageImageFiles"

const containsSinglePdf = files => files.length === 1 && files[0].file.type.endsWith("/pdf")

const allImages = files => files.every(f => f.file.type.startsWith("image/"))

const validateForUpload = (newFiles, presentFiles) => {
    if (!presentFiles.length && (allImages(newFiles) || containsSinglePdf(newFiles))) {
        return true
    }

    if (containsSinglePdf(presentFiles) && newFiles.length) {
        return false
    }

    return allImages(newFiles)
}


const removeFileFunctionWith = (formik) => {
    const {values, setFieldValue} = formik
    return removed => {
        let pageImageFiles = values.pageImageFiles
        const index = pageImageFiles.findIndex(f => f.file.name === removed.file.name)
        pageImageFiles.splice(index, 1)
        setFieldValue(PAGE_IMAGE_FILES, pageImageFiles)
    }
}

const addFilesFunctionWith = (formik) => {
    return (filesToAdd, errorMessage) => {
        const {values, setFieldError, setFieldValue} = formik
        const alreadyAddedFiles = Array.isArray(values.pageImageFiles) ? values.pageImageFiles : []
        if (validateForUpload(filesToAdd, alreadyAddedFiles)) {
            setFieldValue(PAGE_IMAGE_FILES, alreadyAddedFiles.concat(filesToAdd))
        } else {
            setFieldError(PAGE_IMAGE_FILES, errorMessage)
        }
    }
}

export {removeFileFunctionWith, addFilesFunctionWith}