import useRequestStatusDataLoader from "../../../app/status/hooks";
import PropTypes from "prop-types";
import {useState} from "react";
import {useInterval} from "../../../app/common/hooks";


const RequestStatusDataProvider = ({render, pageSize}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const data = useRequestStatusDataLoader(currentPage, pageSize, refreshTrigger);
    useInterval(() => setRefreshTrigger(t => t + 1), 3000);
    return (
        <>
            {render(data.content, setCurrentPage, pageSize, data.totalElements)}
        </>
    );
};

RequestStatusDataProvider.propTypes = {
    render: PropTypes.func.isRequired,
    pageSize: PropTypes.number
};

RequestStatusDataProvider.defaultProps = {
    pageSize: 10
}

export default RequestStatusDataProvider;