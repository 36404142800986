import React from 'react';
import {FormHelperText, Input, MenuItem, Select} from "@material-ui/core";
import FormElementLoading from "./FormElementLoading";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    pos: {
        textAlign: 'left'
    },
    error: {
        textAlign: 'left',
        borderBottom: 'red 2px',
        color: 'red',
        "& span": {
            color: 'red'
        },
        "&::before": {
            borderBottom: '2px red solid'
        }
    },
    emptyLabel: {
        color: 'gray'
    }
}));

const MultiSelectOption = (props) => {
    const classes = useStyles()
    const {data, isLoading, label, error, helperText, ...rest} = props;
    return (isLoading ?
            <FormElementLoading/> :
            <>
                <Select
                    className={error ? classes.error : classes.pos}
                    multiple
                    displayEmpty
                    input={<Input />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <span className={classes.emptyLabel}>{label}</span>
                        }
                        return selected.map(sel => data.find(opt => opt.key === sel).label).join(', ')
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    {...rest}>
                    <MenuItem disabled value="">
                        <span>{label}</span>
                    </MenuItem>
                    {data.map(option => (<MenuItem key={option.key} value={option.key}>{option.label}</MenuItem>))}
                </Select>
                { error && <FormHelperText error>{ helperText }</FormHelperText> }
            </>
    )
}

export default MultiSelectOption;