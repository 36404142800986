import Keycloak from 'keycloak-js'
import config from "../app/config";

const keycloakConfig = {
    url: config.keyCloak.host,
    realm: config.keyCloak.realm,
    clientId: config.keyCloak.client
}

const keycloak = new Keycloak(keycloakConfig);

export default keycloak