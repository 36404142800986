import React from 'react';
import {TableCell, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

const DocumentInfo = ({doc}) => {
    const {t} = useTranslation("uploadForm");
    const history = useHistory();

    // Workaround due to fact ES search returns optional metadata fields "flat"
    // whereas API GW and Frontend expects them to be within "optionalMetadata" object field
    const {
        locationOfAtrocities,
        concentrationCamps,
        atrocitiesPerpetrators,
        atrocitiesVictims,
        atrocitiesTypology,
        atrocitiesNumber
    } = doc;

    if (locationOfAtrocities || concentrationCamps || atrocitiesVictims ||
        atrocitiesPerpetrators || atrocitiesTypology || atrocitiesNumber) {
        doc.optionalMetadata = {
            locationOfAtrocities,
            concentrationCamps,
            atrocitiesVictims,
            atrocitiesNumber,
            atrocitiesPerpetrators,
            atrocitiesTypology
        }
    }

    return (
        <TableRow hover onClick={() => history.push(`/document/${doc.requestId}`)}>
            <TableCell>{doc.title}</TableCell>
            <TableCell>{t(`documentTypes.${doc.documentType}`)}</TableCell>
            <TableCell>{doc.author}</TableCell>
            <TableCell>{t(`languages.${doc.lang}`)}</TableCell>
        </TableRow>)
};

DocumentInfo.propTypes = {
    doc: PropTypes.object.isRequired
}

export default DocumentInfo;