import config from "../config";
import keycloak from "../../auth/keycloak";
import {bearerAuthorizationHeader} from "../../auth/util";

const deleteDocument = (requestId, seqNo, primaryTerm) => {
    const {host} = config.apiGateway;
    const {token} = keycloak;
    const apiEndpoint = `${host}/document/delete?requestId=${requestId}&seqNo=${seqNo}&primaryTerm=${primaryTerm}`;
    return fetch(apiEndpoint, {method: "DELETE", ...bearerAuthorizationHeader(token)})
}

export {
    deleteDocument
}