import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {FormControl} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: theme.padding
    }
}))

const FormElement = (props) => {
    const classes = useStyles();
    const {children, ...rest} = props;
    return (
        <FormControl className={classes.formControl} {...rest}>
            {children}
        </FormControl>
    );
};

export default FormElement;