import React from 'react';
import {MenuItem, TextField} from "@material-ui/core";
import FormElementLoading from "./FormElementLoading";

const SelectOption = (props) => {
    const {data, isLoading, ...rest} = props;
    return (isLoading ?
        <FormElementLoading/> :
        <TextField
            type="text"
            select
            variant="standard"
            {...rest}>
            {data.map(option => (<MenuItem key={option.key} value={option.key}>{option.label}</MenuItem>))}
        </TextField>);
};

export default SelectOption;