import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div style={{textAlign: "left"}}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabClearText: {
        textTransform: "none"
    }
}));

export default function TabbedContainer({tabsSpecs}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" variant="elevation">
                <Tabs variant="fullWidth"
                      component="div"
                      value={value}
                      onChange={handleChange}
                      aria-label="tabbed container">
                    {tabsSpecs.map((spec, index) => <Tab style={{textTransform: "none"}}
                                                         key={index}
                                                         label={spec.title} {...a11yProps(index)} />)}
                </Tabs>
            </AppBar>
            {tabsSpecs.map((spec, index) =>
                <TabPanel key={index} value={value} index={index}>
                    {spec.render(index)}
                </TabPanel>)}
        </div>
    );
}

TabbedContainer.propTypes = {
    tabsSpecs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            render: PropTypes.func.isRequired
        })
    ).isRequired
}