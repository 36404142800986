import React from 'react';
import {DataGrid} from "@material-ui/data-grid";
import PropTypes from "prop-types";
import {CircularProgress} from "@material-ui/core";
import {Done, Error, Help, ReportProblem} from "@material-ui/icons";
import moment from "moment";

const statusIcons = {
    "NOT_EXIST": <Help/>,
    "RUNNING": <CircularProgress size={20}/>,
    "DONE": <Done/>,
    "ERROR": <Error color="error"/>
}

const UTC_OFFSET = moment().local().utcOffset();

const StatusCell = props => {
    return statusIcons[props.value] || <ReportProblem/>;
}

const timestampFormatter = fp => {
    return fp.value? moment(fp.value).add(UTC_OFFSET, "m").format("DD/MM/YYYY - HH:mm:ss") : ""
}

const RequestStatusDataGrid = ({data, onCurrentPageChange, pageSize, totalElements, onRowClick}) => {
    return (
        <DataGrid paginationMode="server"
                  onRowClick={rc => onRowClick(rc.row)}
                  hideFooterSelectedRowCount
                  density="compact"
                  autoHeight
                  pageSize={pageSize}
                  rowsPerPageOptions={[]}
                  rowCount={totalElements}
                  columns={[
                      {field: "uuid", headerName: "Request Id", width: 300},
                      {field: "startedAt", headerName: "Started At", width: 200, valueFormatter: timestampFormatter},
                      {field: "finishedAt", headerName: "Finished At", width: 200, valueFormatter: timestampFormatter},
                      {field: "requestStatusValue", headerName: "Status", renderCell: gcp => <StatusCell {...gcp} />}
                  ]} onPageChange={pageChangeParam => onCurrentPageChange(pageChangeParam)} rows={data}
                  getRowId={row => row.uuid}/>
    );
}

RequestStatusDataGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onCurrentPageChange: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onRowClick: PropTypes.func
};

RequestStatusDataGrid.defaultProps = {
    onRowClick: () => {}
}


export default RequestStatusDataGrid;