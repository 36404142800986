import React from 'react';
import PropTypes from 'prop-types';


const SingleOption = ({text, value}) => <div key={value}>{text}</div>;

SingleOption.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
}

export default SingleOption;