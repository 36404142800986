import {createTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {AppBar, CssBaseline, MenuItem, Toolbar} from "@material-ui/core";
import {ReactKeycloakProvider} from '@react-keycloak/web'
import React, { Suspense, useEffect, useState } from 'react';
import {BrowserRouter as Router, Link, Switch} from "react-router-dom";
import Home from "./ui/pages/Home";
import Upload from "./ui/pages/Upload";
import Search from "./ui/pages/Search";
import keycloak from "./auth/keycloak";
import './i18n'
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SingleDocument from "./ui/pages/SingleDocument";
import PrivateRoute from "./ui/components/auth/PrivateRoute";
import FormElementLoading from "./ui/components/FormElementLoading";
import Edit from "./ui/pages/Edit";

const theme = createTheme({
    padding: "10px",
});

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    authButton: {
        marginLeft: 'auto', // Align the button to the right
    },
}));

const AuthButton = props => (<>
    {keycloak && keycloak.authenticated &&
    <MenuItem
        {...props}
        color="inherit"
        onClick={() => keycloak.logout()}>
        {`Logout [${keycloak.tokenParsed.preferred_username}]`}
    </MenuItem>}
</>)

function App() {
    const classes = useStyles();
    const [isDesktop, setIsDesktop] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            const isDesktopScreen = window.matchMedia('(min-width: 768px)').matches;
            setIsDesktop(isDesktopScreen);
        };
        handleResize(); // Call the handler initially
        window.addEventListener('resize', handleResize); // Add event listener for resizing
        return () => {
            window.removeEventListener('resize', handleResize); // Clean up the event listener
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={'Loading...'}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            <ReactKeycloakProvider authClient={keycloak} LoadingComponent={<FormElementLoading/>}>
                <CssBaseline/>
                <div className={classes.root}>
                    <Router>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AppBar position="static">
                                    <Toolbar
                                        style={
                                            isDesktop
                                                ? { justifyContent: 'space-between' }
                                                : { justifyContent: 'flex-start' }
                                        }
                                    >
                                        <MenuItem component={Link} to="/">Home</MenuItem>
                                        <MenuItem component={Link} to="/search">Search</MenuItem>
                                        <MenuItem component={Link} to="/upload">Upload</MenuItem>
                                        <AuthButton className={isDesktop ? classes.authButton : ''}/>
                                    </Toolbar>
                                </AppBar>
                            </Grid>
                            <Switch>
                                <PrivateRoute exact path="/" component={Home}/>
                                <PrivateRoute path="/upload" component={Upload}/>
                                <PrivateRoute path="/search" component={Search}/>
                                <PrivateRoute path="/document/:id" component={SingleDocument}/>
                                <PrivateRoute path="/edit/:id" component={Edit}/>
                            </Switch>
                        </Grid>
                    </Router>
                </div>
            </ReactKeycloakProvider>
            </MuiPickersUtilsProvider>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;
