import {useEffect, useState} from "react";
import config from "../config";
import {useKeycloak} from "@react-keycloak/web";
import {bearerAuthorizationHeader} from "../../auth/util";

const pageData = (content, totalPages, totalElements) => ({content, totalPages, totalElements});

function buildUploadStatusUrl(page, pageSize) {
    const {apiGateway: {host: apiHost}} = config;
    const url = new URL(`${apiHost}/document/upload/all`);
    url.searchParams.append("page", page);
    url.searchParams.append("size", pageSize);
    url.searchParams.append("sort", "started_at,desc");
    url.searchParams.append("sort", "doc_id")
    return url;
}

export default function useRequestStatusDataLoader(page, pageSize, refreshTrigger) {
    const {keycloak: {token}} = useKeycloak();
    const [data, setData] = useState(pageData([], 0, 0))
    const url = buildUploadStatusUrl(page, pageSize).toString();
    useEffect(() => {
        fetch(url, bearerAuthorizationHeader(token))
                .then(resp => resp.json()).then(data => setData(pageData(data.content, data.totalPages, data.totalElements)))
                .catch(err => console.log(err, "Handle better this error!")) // TODO: Error handling requires revamp!
        }, [page, pageSize, url, token, refreshTrigger]);
    return data;
}