import React from 'react';
import PropTypes from "prop-types";
import TabbedContainer from "../../container/TabbedContainer";
import {useTranslation} from "react-i18next";
import {CloudDownload} from "@material-ui/icons";
import {List, ListItem, Typography} from "@material-ui/core";

const PDFDownloadLink = ({pdfLink}) => {
    const {t} = useTranslation("document")
    return (
        <div style={{textAlign: "center", width: "50%"}}>
            <CloudDownload/>
            <Typography>
                <a style={{textDecoration: "none", textOverflow: "clip"}}
                   href={pdfLink} target={'_blank'} rel={'noreferrer'}>{t("Download Link")}
                </a>
            </Typography>
        </div>
    );
}

const ListItemLink = (props) => (<ListItem button component="a" {...props} target={'_blank'} rel={'noreferrer'} />);

const fileNameOnly = (longFileName) => {
    const lastSlash = longFileName.lastIndexOf("/");
    return longFileName.substring(lastSlash + 1);
}

const SourceImagesLinks = ({imageUrls}) => (
    <List component="nav">
        {Object.entries(imageUrls).map(([key, value]) =>
            <ListItemLink key={key} href={value}>{fileNameOnly(key)}</ListItemLink>
        )}
    </List>
);

const MinioDownloadLinks = ({minioLinks: {documentUrl, imageUrls}}) => {
    const {t} = useTranslation("document");

    return (
        <TabbedContainer tabsSpecs={[
            {
                title: t("PDF"),
                render: () => <PDFDownloadLink pdfLink={documentUrl} />
            },
            {
                title: t("Images"),
                render: () => <SourceImagesLinks imageUrls={imageUrls}/>
            }]}
        />
    )
}

MinioDownloadLinks.propTypes = {
    minioLinks: PropTypes.shape({
        imageUrls: PropTypes.object,
        documentUrl: PropTypes.string.isRequired
    })
}

export default MinioDownloadLinks;