import React from 'react';
import {IconButton, Snackbar, SnackbarContent} from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";

const InfoSnackbar = ({role, message, handleClose, ...rest}) => {
    return (
        <Snackbar
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} {...rest}>
            <SnackbarContent message={message}
                             role={role}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }/>
        </Snackbar>
    );
};

InfoSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    role: PropTypes.string
}

InfoSnackbar.defaultProps = {
    role: 'alert'
}

export default InfoSnackbar;