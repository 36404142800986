import config from "../config";
import keycloak from "../../auth/keycloak";
import {bearerAuthorizationHeader} from "../../auth/util";

function prepareNewFileName(f, i) {
    let currentFileName = f.name;
    let dotPosition = currentFileName.lastIndexOf(".");
    let fileName = `${currentFileName.slice(0, dotPosition)}-${i}`;
    let extension = currentFileName.slice(dotPosition + 1);
    return `${fileName}.${extension}`;
}

const makeMetadataWrapper = ({
                             author,
                             addressedTo,
                             documentNumber,
                             origin,
                             archive,
                             abstract,
                             topic,
                             keywords,
                             comments,
                             importantPersonalities,
                             pageImageFiles,
                             createdOn,
                             addAdditionalMetadata,
                             locationOfAtrocities,
                             concentrationCamps,
                             atrocitiesNumber,
                             atrocitiesPerpetrators,
                             atrocitiesVictims,
                             atrocitiesTypology,
                             ...rest
                         }, editMode) => {
    const metadataWrapper = {
        metadata: {
            author: coalesce(author),
            addressedTo: coalesce(addressedTo),
            documentNumber: coalesce(documentNumber),
            origin: coalesce(origin),
            archive: coalesce(archive),
            abstract: coalesce(abstract),
            topic: coalesce(topic),
            keywords: coalesce(keywords),
            comments: coalesce(comments),
            importantPersonalities: coalesce(importantPersonalities),
            ...rest,
            createdOn: editMode ? createdOn : createdOn?.format("YYYY-MM-DD")
        }
    }
    if (addAdditionalMetadata) {
        metadataWrapper.metadata.optionalMetadata = {
            locationOfAtrocities: coalesce(locationOfAtrocities),
            concentrationCamps: coalesce(concentrationCamps),
            atrocitiesNumber: coalesce(atrocitiesNumber),
            atrocitiesPerpetrators: coalesce(atrocitiesPerpetrators),
            atrocitiesVictims: coalesce(atrocitiesVictims),
            atrocitiesTypology: coalesce(atrocitiesTypology),
        }
    } else if (editMode) {
        metadataWrapper.metadata.optionalMetadata = null
    }

    return metadataWrapper
}

function coalesce(value) {
    if (null === value) { return null }
    if (Array.isArray(value) && value.length === 0) { return null }
    if (value === '') { return null } else { return value }
}

function uploadControllerRequestFrom({pageImageFiles, ...values}, editMode) {
    const metadataWrapper = makeMetadataWrapper(values, editMode)

    const formData = new FormData();
    formData.append("metadata", new Blob([JSON.stringify(metadataWrapper)], {type: "application/json"}));
    pageImageFiles.forEach((f, i) => {
        let newFileName = prepareNewFileName(f.file, i);
        formData.append("pageImages", f.file, newFileName)
    });
    return formData;
}

const updateEndpoint = ({requestId, seqNo, primaryTerm}) => {
    return `/document/metadata/update?requestId=${requestId}&seqNo=${seqNo}&primaryTerm=${primaryTerm}`
}

export async function uploadForm({editMode, ...formValues}) {
    const {host} = config.apiGateway;
    const apiEndpoint = host + (editMode ? updateEndpoint(formValues) : "/document/upload");
    const {token} = keycloak;
    if (editMode) {
        const {metadata} = makeMetadataWrapper(formValues, editMode)
        let {headers} = bearerAuthorizationHeader(token)
        headers["Content-Type"] = "application/json"
        return fetch(apiEndpoint, {
            method: "POST",
            body: JSON.stringify(metadata),
            headers
        })
    } else {
        const formData = uploadControllerRequestFrom(formValues, editMode);
        return fetch(apiEndpoint, {method: "POST", body: formData, ...bearerAuthorizationHeader(token)});
    }
}