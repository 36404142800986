import React from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    frame: {
        borderRadius: '5px'
    }
}));

const Panel = ({children, title}) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <fieldset className={classes.frame}>
                <legend>
                    <Typography variant="body1" gutterBottom>{title}</Typography>
                </legend>
                <Box className={classes.paper}>
                    {children}
                </Box>
            </fieldset>
        </Grid>
    );
};

Panel.propTypes = {
    title: PropTypes.string.isRequired
}

export default Panel;