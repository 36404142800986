import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const YesNoConfirmationDialog = ({open, okClicked, noClicked, title, children, showActions, ...other}) => {
    const {t} = useTranslation("common");
    return (
        <Dialog
            {...other}
            fullScreen
            maxWidth="xs"
            disableBackdropClick
            disableEscapeKeyDown
            showActions
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {showActions && <DialogActions>
                <Button onClick={okClicked} color="secondary">
                    {t("Yes Button")}
                </Button>
                <Button onClick={noClicked} color="primary" autoFocus>
                    {t("No Button")}
                </Button>
            </DialogActions>}
        </Dialog>
    );
};

YesNoConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    okClicked: PropTypes.func.isRequired,
    noClicked: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    showActions: PropTypes.bool
}

YesNoConfirmationDialog.defaultProps = {
    showActions: true
}

export default YesNoConfirmationDialog;