import React from 'react';
import {FieldArray, FormikProvider} from "formik";
import {Box, Chip, FormLabel, InputAdornment, Paper, TextField} from "@material-ui/core";
import {AddCircle} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    items: {
        marginTop: "20px"
    },
    chip: {
        margin: "5px"
    },
    noChip: {
        margin: "10px",
        padding: "10px"
    }
}));

const ItemsChipSetFormInput = ({
                                   formik,
                                   entryLabel,
                                   entryValue,
                                   entryFieldName,
                                   itemsName,
                                   itemsValue,
                                   noItemsLabel
                               }) => {

    const {setFieldValue, handleChange} = formik;

    const classes = useStyles();

    const pushItem = (e, arrayHelpers) => {
        if ((e.type === "keydown" && e.key === "Enter") || e.type === "click") {
            e.preventDefault();
            if (entryLabel === "") {
                return;
            }
            arrayHelpers.push(entryValue);
            setFieldValue(entryFieldName, "");
        }
    }

    return (
        <FormikProvider value={formik}>
            <FieldArray name={itemsName} render={arrayHelpers => {
                return (<>
                    <TextField
                        label={entryLabel}
                        name={entryFieldName}
                        value={entryValue}
                        onKeyDown={e => pushItem(e, arrayHelpers)}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment:
                                <InputAdornment onClick={e => pushItem(e, arrayHelpers)}
                                                position="start">
                                    <AddCircle/>
                                </InputAdornment>
                        }}
                    />
                    <Paper className={classes.items}>
                        {itemsValue && itemsValue.length > 0 ?
                            itemsValue.map((iv, index) =>
                                <Chip className={classes.chip}
                                      key={index}
                                      label={iv}
                                      onDelete={() => arrayHelpers.remove(index)}
                                      color="primary"/>)
                            : <Box className={classes.noChip}>
                                <FormLabel>{noItemsLabel}</FormLabel>
                            </Box>
                        }
                    </Paper>
                </>)
            }}/>
        </FormikProvider>
    );
};

ItemsChipSetFormInput.propTypes = {
    formik: PropTypes.object.isRequired,
    entryLabel: PropTypes.string.isRequired,
    entryValue: PropTypes.string.isRequired,
    entryFieldName: PropTypes.string.isRequired,
    itemsName: PropTypes.string.isRequired,
    itemsValue: PropTypes.array.isRequired,
    noItemsLabel: PropTypes.string.isRequired
}

export default ItemsChipSetFormInput;